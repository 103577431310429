import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import BTextareaInputValidation from '@/components/ui/inputs/b-textarea-input-validation/bTextareaInputValidation.vue';
import { showToast } from '@/helpers/toast.helper';
import { ProductCard } from '@/store/modules/products/actions';

@Component({
  name: 'UpdateProductKeywordModal',
  components: {
    ValidationObserver,
    BTextareaInputValidation,
  },
})
export default class UpdateProductKeywordModal extends Vue {
  tryingToUpdate = false;
  keywords = '';

  @Prop({
    required: true
  })
  updateCallback!: (response?: any) => any;

  @Prop({
    required: true
  })
  productId!: number;

  @Action('productAddKeywords', { namespace: 'products' })
  productAddKeywords!: (data: ProductCard<string[]>) => Promise<void>;

  cancel() {
    this.$emit('close');
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      const keywords = this.keywords.split(',').map(item => item.trim());
      this.productAddKeywords({
        id: this.productId,
        params: keywords.length ? keywords : []
      })
        .then(response => {
          this.tryingToUpdate = false;
          this.updateCallback(response);
          this.cancel();
          showToast(this.$t('common.successUpdate') as any);
        })
        .catch(() => {
          this.tryingToUpdate = false;
          showToast(this.$t('common.errorUpdate') as any);
        });
    }
  }
}
