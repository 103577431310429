<template>
  <div class="keyword-statistics-report mb-1">
    <div>
      {{ value }}
      <span
        v-if="valueChanged"
        :class="{ 'text-success': valueChanged > 0, 'text-danger': valueChanged < 0, 'font-11': true }"
      >
        <span class="text-success" v-if="valueChanged > 0">+</span> {{ valueChanged }}
      </span>
    </div>
    <div class="text-muted font-11">{{ title }}</div>
  </div>
</template>

<script type="tsx">
export default {
  name: 'KeywordStatisticsCell',
  props: ['title', 'value', 'valueChanged'],
};
</script>
<style scoped lang="scss">
.keyword-statistics-report {
  text-align: center;
}
</style>
