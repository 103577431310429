import { render, staticRenderFns } from "./sfr-chart.vue?vue&type=template&id=7683bf40&scoped=true&"
import script from "./sfr-chart.ts?vue&type=script&lang=ts&"
export * from "./sfr-chart.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7683bf40",
  null
  
)

export default component.exports