import { Component, Prop, Vue } from 'vue-property-decorator';
import LineChart from '@/components/ui/charts/line-chart/lineChart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { COLOR_GREEN_10_BASE, COLOR_LIGHT, COLOR_YELLOW_10 } from '@/constants/color.constant';
import { numberFormatter } from '@/helpers/string.helper';
import { isMobile } from '@/helpers/app.helper';
import { KeywordType } from '@/types/api/products/keyword.type';
import { getShowingDate, MD_FORMAT } from '@/helpers/date.helper';

@Component({
  name: 'KeywordsChart',
  components: {
    LineChart,
  },
})
export default class KeywordsChart extends Vue {
  @Prop({ required: true })
  data!: KeywordType;

  @Prop({ required: false })
  height?: number;

  get chartData(): ChartData {
    return {
      labels: this.data.data.map(item => getShowingDate(item.date, { format: MD_FORMAT })),
      datasets: [
        {
          label: this.$t('keywordStatisticsReport.organic') as string,
          data: this.data.data.map(item => item.organicPosition),
          type: 'line',
          borderColor: COLOR_GREEN_10_BASE,
          backgroundColor: COLOR_GREEN_10_BASE,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        },
        {
          label: this.$t('keywordStatisticsReport.sponsored') as string,
          data: this.data.data.map(item => item.sponsoredPosition),
          type: 'line',
          borderColor: COLOR_YELLOW_10,
          backgroundColor: COLOR_YELLOW_10,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        }
      ]
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: !isMobile(),
      interaction: {
        intersect: false,
      },
      animation: {
        onProgress: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgb(171, 169, 169)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              const padding = -15;
              meta.data.forEach((bar, index) => {
                const value = numberFormatter(item.data?.[index] as number, { fractionPartCount: 0 });
                ctx.fillText(
                  item.data?.[index] ? `${value}` : '',
                  bar.x,
                  bar.y - padding
                );
              });
            }
          })
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
        },
        y: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
          beginAtZero: true,
          afterFit: () => 1050
        },
      }
    }
  }

  get chartWidth() {
    return isMobile() ? 1200 : undefined;
  }
}
