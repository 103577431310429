import { Component, Prop, Vue } from 'vue-property-decorator';
import LineChart from '@/components/ui/charts/line-chart/lineChart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { COLOR_LIGHT, getColorByIdx } from '@/constants/color.constant';
import { numberFormatter } from '@/helpers/string.helper';
import { isMobile } from '@/helpers/app.helper';
import { getShowingDate, MD_FORMAT } from '@/helpers/date.helper';
import { SfrType } from '@/types/api/reports/sfr.type';

@Component({
  name: 'SfrChart',
  components: {
    LineChart,
  },
})
export default class SfrChart extends Vue {
  @Prop({ required: true })
  data!: SfrType[];

  @Prop({ required: false })
  height?: number;

  get chartData(): ChartData {
    const keywords: { [key: string]: number[] } = {}
    const dates: { [key: string]: number } = {}

    this.data.forEach(item => {
      keywords[item.keyword] = keywords[item.keyword]
        ? [...keywords[item.keyword], item.rank]
        : [item.rank]
      dates[item.date] = (dates[item.date] || 0) + (item.rank ? 1 : 0 )
    })

    return {
      labels: Object.entries(dates).filter(item => item[1]).map(item => getShowingDate(item[0], { format: MD_FORMAT })),
      datasets: Object.entries(keywords).map(([label, data], idx) => ({
        label,
        data,
        type: 'line',
        borderColor: getColorByIdx(idx),
        backgroundColor: getColorByIdx(idx),
        cubicInterpolationMode: 'monotone',
        tension: 0.4
      }))
    };
  }

  get chartOptions(): ChartOptions {
    return {
      responsive: !isMobile(),
      interaction: {
        intersect: false,
      },
      animation: {
        onProgress: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgb(171, 169, 169)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              const padding = -15;
              meta.data.forEach((bar, index) => {
                const value = numberFormatter(item.data?.[index] as number, { fractionPartCount: 0 });
                ctx.fillText(
                  item.data?.[index] ? `${value}` : '',
                  bar.x,
                  bar.y - padding
                );
              });
            }
          })
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
        },
        y: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
          beginAtZero: true,
          afterFit: () => 1050
        },
      }
    }
  }

  get chartWidth() {
    return isMobile() ? 1200 : undefined;
  }
}
