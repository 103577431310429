import { Component, Prop, Mixins } from 'vue-property-decorator';
import ModalMixin from '@/mixins/modal.mixin';

@Component({
  name: 'ShowReportModal',
})
export default class ShowReportModal extends Mixins(ModalMixin) {
  @Prop({
    required: true,
    type: String
  })
  title!: string;

  @Prop({
    type: String,
    required: false,
  })
  subTitle?: string;

  @Prop({
    required: true
  })
  reportComponent!: any;

  @Prop({
    required: false
  })
  data?: any;

  cancel() {
    this.$emit('close');
  }
}
