import { Component, Prop, Vue } from 'vue-property-decorator';
import { BButton } from 'bootstrap-vue';

interface IOptions {
  text: string,
  value: number
}

@Component({
  name: 'SwitcherBtn',
  components: {
    BButton
  }
})
export default class SwitcherBtn extends Vue {
  mode = 0;

  @Prop({
    required: true,
    default: () => []
  })
  options!: IOptions[];

  @Prop({
    required: false
  })
  defaultMode!: number;

  created() {
    this.mode = this.defaultMode || this.options?.[0]?.value || 0;
  }

  get modeName() {
    const mode = this.options.find(item => item.value === this.mode);
    return mode?.text || '';
  }

  onChange() {
    const index = this.options.findIndex(item => item.value === this.mode);
    const nextIndex = index + 1 >= this.options.length ? 0 : index + 1;
    this.mode = this.options[nextIndex].value;

    this.$emit('change', this.mode);
  }
}
