import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import Box from '@/components/ui/box/box.vue';
import { DateTime } from 'luxon';
import {
  DATE_MODE_DAILY,
  DATEPICKER_FORMAT,
  DATE_FORMAT
} from '@/helpers/date.helper';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import { BrandType } from '@/types/api/brands/brand.type';
import DatepickerRangeMode from '@/components/ui/inputs/datepicker-range-mode/datepicker-range-mode.vue';
import { BFormRadioGroup, BAlert, VBTooltip } from 'bootstrap-vue';
import AutocompleteInput from '@/components/ui/inputs/autocomplete-input/autocompleteInput.vue';
import ProductDropdownInput from '@/components/ui/inputs/product-dropdown-input/product-dropdown-input.vue';
import KeywordStatisticsGrid from '@/components/features/grids/keyword-statistics-grid/keyword-statistics-grid.vue';
import ProductKeywordsGrid from '@/components/features/grids/product-keywords-grid/product-keywords-grid.vue';
import SwitcherBtn from '@/components/ui/buttons/switcher-btn/switcher-btn.vue';

const title = 'keywordStatisticsReport.title';
enum MODE {
  products,
  keywords,
}

@Component({
  name: 'KeywordStatisticsReport',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    BoxHeader,
    Box,
    DatepickerRangeMode,
    BFormRadioGroup,
    BAlert,
    AutocompleteInput,
    KeywordStatisticsGrid,
    ProductKeywordsGrid,
    SwitcherBtn,
    ProductDropdownInput,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
})
export default class KeywordStatisticsReport extends Mixins(FilterMixin) {
  title = title;
  filter: any = this.initFilter();
  saveFilter = true;
  pageFilter: any = {
    brandId: this.$route.query.brandId || null,
    type: DATE_MODE_DAILY
  }
  selectedBrand: BrandType | null = null;
  selectedProduct: ProductType | null = null;
  useBrandFilter = true;
  mode = MODE.products;

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  @Action('getBrands', { namespace: 'brands' })
  getBrands!: () => Promise<BrandType[]>;

  @Getter('getBrands', { namespace: 'brands' })
  brands!: BrandType[];

  created() {
    this.filter.from = this.$route.query.from || this.startTodayDate;
    this.filter.to = this.$route.query.to || this.endTodayDate;

    if (this.pageFilter.brandId) {
      this.setBrandById(this.pageFilter.brandId);
      this.getProductsRequest(this.pageFilter.brandId);
    }
  }

  getProductsRequest(value) {
    this.getProducts({ brandId: value })
      .then(response => {
        this.selectedProduct = response.find(item => item.id === Number(this.filter.productId)) || null;
      });
  }

  get preparedFilter() {
    return {
      ...this.filter,
      ...this.pageFilter,
      from: DateTime.fromFormat(this.filter.from, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
      to: DateTime.fromFormat(this.filter.to, DATEPICKER_FORMAT).toFormat(DATE_FORMAT),
    }
  }

  get startTodayDate() {
    return DateTime.local().setZone('utc').toFormat(DATEPICKER_FORMAT);
  }

  get endTodayDate() {
    return DateTime.local().toFormat(DATEPICKER_FORMAT);
  }

  initFilter() {
    return {
      from: this.$route.query.from || null,
      to: this.$route.query.to || null,
      productId: this.$route.query.productId || null,
      keyword: this.$route.query.keyword || null,
      productName: this.$route.query.productName || null,
      asin: this.$route.query.asin || null,
    };
  }

  get brandOptions() {
    return [
      { text: '---', value: null },
      ...this.brands.map(item => ({ text: item.displayName, value: item.id }))
    ];
  }

  onSelectBrand(value) {
    this.setBrandById(value);
    this.getProductsRequest(value);
  }

  setBrandById(id: number) {
    const brand = this.brands.find(item => item.id === Number(id));
    if (brand) {
      this.selectedBrand = brand;
    }
  }

  get modeOptions() {
    return [
      { text: this.$t('keywordStatisticsReport.products'), value: MODE.products },
      { text: this.$t('keywordStatisticsReport.keywords'), value: MODE.keywords },
    ]
  }

  onChangeMode(value: MODE) {
    this.mode = value;
  }

  get modeProducts() {
    return this.mode === MODE.products;
  }
}
