import { Component, Mixins, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import GridTable from '@/components/ui/grid-table/gridTable.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { GridTableOptions, GridTablePaginationParams } from '@/components/ui/grid-table/gridTable.ts';
import GridMixin from '@/mixins/grid.mixin';
import { KeywordsStatisticsRequest } from '@/types/request/reports-request.types';
import { ListResponseType } from '@/types/response/list-response.type';
import FilterMixin from '@/mixins/filter.mixin';
import { KeywordStatisticType } from '@/types/api/reports/keyword-statistic.type';
import ImageCellRenderer from '@/components/ui/grid-table/cell/image-cell-renderer/image-cell-renderer.vue';
import { VBTooltip } from 'bootstrap-vue';
import { DATE_FORMAT, daysInterval } from '@/helpers/date.helper';
import { DateTime } from 'luxon';
import KeywordStatisticsCell from '@/components/pages/reports/keyword-statistics-report/keyword-statistics-cell.vue';
import ProductKeywordsGrid from '@/components/features/grids/product-keywords-grid/product-keywords-grid.vue';
import { debounceFilter } from '@/helpers/filter.helper';

const DEFAULT_SORT = {
  sortBy: 'keywords',
  sortDesc: true
};

@Component({
  name: 'KeywordStatisticsGrid',
  components: {
    GridTable,
    PageTitle,
    ImageCellRenderer,
    KeywordStatisticsCell,
    ProductKeywordsGrid,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
})
export default class KeywordStatisticsGrid extends Mixins(GridMixin, FilterMixin) {
  tableName = 'keywords';
  tableOptions: GridTableOptions = {
    pagination: {
      callback: this.gridChangeCallback,
    },
    sort: DEFAULT_SORT,
    rowClickable: true,
    hasDetails: true,
  };
  keywordsQuery = '';

  @Prop({
    required: true,
    default: () => ({})
  })
  gridFilter?: KeywordsStatisticsRequest;

  @Prop({
    required: false
  })
  title?: string;

  @Action('getKeywordsStatistics', { namespace: 'reports' })
  getKeywordsStatistics!: (params?: KeywordsStatisticsRequest) => Promise<ListResponseType<KeywordStatisticType>>;

  created() {
    this.debounceApplyKeywordsFilter = debounceFilter(this.debounceApplyKeywordsFilter);
    this.filter = this.gridFilter as any;
  }

  gridChangeCallback(params: GridTablePaginationParams) {
    return this.getKeywordsStatistics({
      ...this.paginationFilter(params),
      ...this.prepareFilter(),
    });
  }

  get tableFields() {
    let fields: any = [
      {
        label: this.$t('common.image'),
        key: 'imageUrl',
        // stickyColumn: true,
        thStyle: { width: '150px' },
        formatter: (value: string) => value ? { url: value } : {},
      },
      {
        label: this.$t('keywordStatisticsReport.name'),
        key: 'name',
        stickyColumn: true,
        sortable: true,
        thClass: ['kws-bg-white'],
      },
      {
        label: this.$t('keywordStatisticsReport.trackedKeywords'),
        key: 'keywords',
        sortable: true,
        formatter: (_1, _2, item: KeywordStatisticType) => item.trackedKeywords,
      },
    ];

    this.dateIntervals.forEach(dateItem => {
      fields = [
        ...fields,
        {
          label: dateItem,
          key: dateItem,
          thClass: 'text-center',
          formatter: (_1, _2, item: KeywordStatisticType) => item?.statistics?.[dateItem],
        },
      ]
    })

    return fields;
  }

  get dateIntervals() {
    return daysInterval(
      DateTime.fromFormat(this.filter.from, DATE_FORMAT),
      DateTime.fromFormat(this.filter.to, DATE_FORMAT)
    )
  }

  getNamePreview(value: string) {
    return value?.slice(0, 40);
  }

  getStatistic(item: KeywordStatisticType, attr: string) {
    return item?.[attr];
  }

  debounceApplyKeywordsFilter(event) {
    this.keywordsQuery = event;
  }
}
