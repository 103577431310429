import { Component, Prop, Vue } from 'vue-property-decorator';
import { IGridUI } from '@/components/features/grids/grid-ui.interface';
import { GridTableApi, GridTableCallbackResponse, GridTableOptions } from '@/components/ui/grid-table/gridTable.ts';

@Component
export default class GridMixin extends Vue implements IGridUI {
  tableName?: string;
  table?: GridTableApi | any;
  tableOptions: GridTableOptions = {};
  gridData: GridTableCallbackResponse<any> | null = null;

  private tableDataLoaded: boolean | null = null;

  @Prop({
    type: Boolean,
    required: false,
    default: () => false
  })
  hideNoData?: boolean; // Hide grid if no data loaded

  @Prop({
    required: false,
    default: () => ({})
  })
  gridOptions?: GridTableOptions;

  created() {
    this.tableOptions = {
      ...this.tableOptions,
      ...this.gridOptions
    }
  }

  mounted() {
    if (this.tableName) {
      this.table = (this.$refs[this.tableName] as any);
      this.table.$on('on-get-data', (data: GridTableCallbackResponse<any>) => {
        this.tableDataLoaded = data.total > 0;
      });
    }
  }

  toggleDetails(row) {
    this.table?.toggleDetails();
  }

  refreshTable(isEmit = true) {
    this.table?.refresh();
    if (isEmit) {
      this.$emit('refresh-table');
    }
  }

  onGetData(data: GridTableCallbackResponse<any>) {
    this.gridData = data;
    this.$emit('on-get-data', data);
  }

  get isLoadedData() {
    if (this.hideNoData) {
      return this.tableDataLoaded === null ? true : this.tableDataLoaded;
    }

    return true;
  }
}
