import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import isFunction from 'lodash/isFunction';
import { generateRandomString } from '@/helpers/string.helper';
import { isMobile } from '@/helpers/app.helper';
import NotFoundIcon from '@/components/ui/icons/not-found-icon/not-found-icon.vue';
import { BPagination } from 'bootstrap-vue';

/**
 * Available td classes:
 * - cell-visible (Set overflow: visible)
 * - no-wrap-disable (Set white-space: unset)
 *
 * Available custom slots:
 * - pagi-data-exist (Show slot items in left side of pagination block when grid data exists)
 * - pagi-show-always (Always show slot items in left side of pagination block)
 */

const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_TOTAL_COUNT = 0;
const DEFAULT_PER_PAGE = 20;

export interface GridTableApi {
  refresh: () => any;
  getData: () => any[];
  selectAllRows: () => void;
  clearSelected: () => void;
  selectRow: (index: number) => void;
  unselectRow: (index: number) => void;
}

export interface IGridTableSort {
  sortBy: string;
  sortDesc: boolean;
}

export interface GridTableOptions {
  pagination?: {
    callback: (params: GridTablePaginationParams, options: GridTableOptions) => Promise<GridTableCallbackResponse<any>>;
  },
  paginationOptions?: {
    perPage: number;
  };
  callback?: (params: GridTableRequestParams, options: GridTableOptions) => Promise<any[]>;
  sort?: {
    sortBy: string;
    sortDesc: boolean;
  },
  rowClickable?: boolean;
  maxRowsShowing?: number;
  selectable?: boolean;
  noSelectOnClick?: boolean;
  selectMode?: 'multi' | 'single' | 'range',
  responsive?: string | boolean;
  'sticky-header'?: boolean;
  hasDetails?: boolean;
}

export interface GridTablePaginationParams {
  pageNumber: number;
  pageLimit: number;
  sort?: any;
}

export interface GridTableRequestParams {
  sort?: IGridTableSort;
}

export interface GridTableCallbackResponse<T> {
  result: T[];
  total: number;
}

export interface GridTableCellData<T> {
  detailsShowing: boolean;
  field: { label: string, key: string };
  index: number;
  item: T;
  rowSelected: boolean;
  selectRow: () => any;
  toggleDetails: () => any;
  unformatted: string;
  unselectRow: () => any;
  value: string;
}

@Component({
  components: {
    NotFoundIcon,
    BPagination
  }
})
export default class GridTable extends Vue {
  id = generateRandomString();
  items: any = [];
  loading = false;
  totalCount = DEFAULT_TOTAL_COUNT;
  currentPage = DEFAULT_CURRENT_PAGE;
  rows: NodeListOf<HTMLDivElement> | null = null;
  rowsShowing = false;
  sort?: IGridTableSort;
  gridTable?: any;

  defaultOptions: GridTableOptions = {
    paginationOptions: {
      perPage: DEFAULT_PER_PAGE
    },
    rowClickable: false,
    selectable: false,
    noSelectOnClick: false,
    selectMode: 'multi',
    responsive: 'sm',
    'sticky-header': false,
    hasDetails: false,
  };

  @Prop({
    type: Array,
    required: true
  })
  fields;

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
  data!: any[];

  @Prop({
    type: Object,
    required: false,
  })
  options?: GridTableOptions;

  @Watch('data')
  dataChange(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.items = this.preparedData;
    }
  }

  @Watch('currentPage')
  paginate(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.sendRequest();
    }
  }

  mounted() {
    this.gridTable = this.$refs.gridTable;

    if (this.gridOptions.sort) {
      this.sort = this.gridOptions.sort;
    }

    if (this.gridOptions.pagination || isFunction(this.gridOptions.callback)) {
      this.sendRequest();
    } else {
      this.items = this.preparedData;
    }

    if (this.gridOptions.maxRowsShowing) {
      const table = (this.$refs.gridTable as any).$el as HTMLDivElement;

      this.rows = table.querySelector('tbody')!.querySelectorAll('tr');
      this.setRowsShowing();
    }
  }

  get preparedData() {
    return this.data.map(item => ({
      ...item,
      _showDetails: false
    }));
  }

  setRowsShowing(show = false) {
    this.rows!.forEach((row, index) => {
      if (index >= this.gridOptions.maxRowsShowing!) {
        if (show) {
          row.classList.remove('hidden');
        } else {
          row.classList.add('hidden');
        }
      }
    });
  }

  get gridOptions(): GridTableOptions {
    return {
      ...this.defaultOptions,
      ...(this.options || {})
    }
  }

  sendPaginationRequest() {
    if (isFunction(this.gridOptions.pagination?.callback)) {
      this.loading = true;
      this.gridOptions.pagination!.callback({
        pageNumber: this.currentPage,
        pageLimit: this.gridOptions.paginationOptions!.perPage,
        sort: this.sort
      }, this.gridOptions)
        .then((result: GridTableCallbackResponse<any>) => this.parseResponse(result.result, result.total))
        .catch(() => {
          this.loading = false;
        });
    }
  }

  sendSimpleRequest() {
    if (isFunction(this.gridOptions.callback)) {
      this.loading = true;
      this.gridOptions.callback({
        sort: this.sort
      }, this.gridOptions)
        .then((result: any[]) => this.parseResponse(result, result.length))
        .catch(() => {
          this.loading = false;
        });
    }
  }

  parseResponse(data: any[], count: number) {
    this.items = data.map(item => ({
      ...item,
      _showDetails: false
    }));
    this.$emit('on-get-data', {
      items: this.items,
      totalCount: count,
    });
    this.totalCount = count;
    this.loading = false;
  }

  sendRequest() {
    this.sendPaginationRequest();
    this.sendSimpleRequest();
  }

  refresh() {
    if (this.gridOptions.pagination || isFunction(this.gridOptions.callback)) {
      // this.currentPage = DEFAULT_CURRENT_PAGE;
      this.sendRequest();
    }
  }

  getData() {
    return this.items;
  }

  rowsToggle() {
    this.rowsShowing = !this.rowsShowing;
    this.setRowsShowing(this.rowsShowing);
  }

  get isMobile() {
    return isMobile();
  }

  sortingChanged(ctx) {
    if (ctx.sortBy) {
      this.sort = {
        sortBy: ctx.sortBy,
        sortDesc: ctx.sortDesc,
      }
      this.sendRequest();
    }
  }

  toggleDetails(item: any) {
    if (this.gridOptions.hasDetails && !this.gridOptions.selectable) {
      this.getData()
        .filter(itm => itm.id !== item.id)
        .forEach(itm => itm._showDetails = false);
      item._showDetails = !item._showDetails;
    }
  }

  selectAllRows() {
    this.gridTable?.selectAllRows();
  }

  clearSelected() {
    this.gridTable?.clearSelected();
  }

  selectRow(index: number) {
    this.gridTable?.selectRow(index);
  }

  unselectRow(index: number) {
    this.gridTable?.unselectRow(index);
  }
}
