import { Component, Vue } from 'vue-property-decorator';
import { takeHtmlScreenshot } from '@/helpers/actions.helper';

@Component
export default class ModalMixin extends Vue {
  modalName = 'modal';
  modal?: any;

  mounted() {
    if (this.modalName) {
      this.modal = (this.$refs[this.modalName] as any);
    }
  }

  takeScreenshot() {
    if (this.modal) {
      takeHtmlScreenshot(this.modal as HTMLElement);
    }
  }

  cancel() {
    this.$emit('close');
  }
}
