import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import GridTable from '@/components/ui/grid-table/gridTable.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { GridTableOptions, GridTablePaginationParams } from '@/components/ui/grid-table/gridTable.ts';
import GridMixin from '@/mixins/grid.mixin';
import { KeywordsRequest, KeywordsUpdateRequest } from '@/types/request/products-request.types';
import { ProductCard } from '@/store/modules/products/actions';
import ActionsCellRenderer from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer.vue';
import { ActionsCellRendererParams } from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer';
import { MODAL_CONFIG } from '@/helpers/app.helper';
import { showToast } from '@/helpers/toast.helper';
import UpdateProductKeywordModal
  from '@/components/features/modals/update-product-keyword-modal/update-product-keyword-modal.vue';
import { ListResponseType } from '@/types/response/list-response.type';
import FilterMixin from '@/mixins/filter.mixin';
import {
  DATE_FORMAT,
  daysInterval,
  getShowingDate
} from '@/helpers/date.helper';
import { DateTime } from 'luxon';
import { KeywordType } from '@/types/api/products/keyword.type';
import { VBTooltip, BPopover } from 'bootstrap-vue';
import ExportXlsxBtn from '@/components/ui/buttons/export-xlsx-btn/export-xlsx-btn.vue';
import XlsExportMixin from '@/mixins/xls-export.mixin';
import ShowReportModal from '@/components/features/modals/show-report-modal/show-report-modal.vue';
import KeywordsChart from '@/components/ui/charts/keywords-chart/keywords-chart.vue';
import SfrChart from '@/components/ui/charts/sfr-chart/sfr-chart.vue';
import { sfrList } from '@/api/reports.api';

const DEFAULT_SORT = {
  sortBy: 'productName',
  sortDesc: false
};

@Component({
  name: 'ProductKeywordsGrid',
  components: {
    GridTable,
    PageTitle,
    ActionsCellRenderer,
    BPopover,
    ExportXlsxBtn,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
})
export default class ProductKeywordsGrid extends Mixins(GridMixin, FilterMixin, XlsExportMixin) {
  tableName = 'productKeywords';
  tableOptions: GridTableOptions = {
    pagination: {
      callback: this.gridChangeCallback,
    },
    // sort: DEFAULT_SORT,
    selectable: true,
    noSelectOnClick: true,
  };
  selectedItems: KeywordType[] = [];

  @Prop({
    required: true,
  })
  gridFilter!: KeywordsRequest;

  @Prop({
    required: false
  })
  title?: string;

  @Prop({
    required: false,
    default: () => false
  })
  showProductField?: boolean;

  @Action('getKeywords', { namespace: 'products' })
  getKeywords!: (params: KeywordsRequest) => Promise<ListResponseType<KeywordType>>;

  @Getter('getKeywords', { namespace: 'products' })
  exportData!: ListResponseType<KeywordType>;

  @Action('productDeleteKeyword', { namespace: 'products' })
  productDeleteKeyword!: (data: ProductCard<number>) => Promise<void>;

  @Action('productDeleteKeywords', { namespace: 'products' })
  productDeleteKeywords!: (data: ProductCard<number[]>) => Promise<void>;

  @Action('productUpdateKeywords', { namespace: 'products' })
  productUpdateKeywords!: (data: ProductCard<KeywordsUpdateRequest[]>) => Promise<void>;

  @Watch('gridFilter')
  gridFilterChange() {
    this.refreshTable(false);
  }

  created() {
    this.filter = this.gridFilter as any;
  }

  gridChangeCallback(params: GridTablePaginationParams) {
    return this.getKeywords({
      ...this.paginationFilter(params),
      ...this.gridFilter,
    })
  }

  get tableFields() {
    const productNameField = this.showProductField
      ? {
          label: this.$t('products.name'),
          key: 'productName',
        }
      : {}

    let fields: any = [
      {
        label: this.$t('products.keyword'),
        key: 'keyWord',
        thStyle: {
          width: '1%',
          whiteSpace: 'nowrap'
        },
        // sortable: true,
      },
      productNameField,
      {
        label: '',
        key: 'isFavourite',
        thStyle: { width: '50px' },
      },
      {
        label: 'SFR',
        key: 'sfr',
      },
    ];

    this.dateIntervals.forEach(dateItem => {
      fields = [
        ...fields,
        {
          label: dateItem,
          key: dateItem,
          thClass: 'text-center',
          thStyle: { minWidth: '180px' },
          formatter: (_1, _2, item: KeywordType) =>
            item?.data.find(dItem => DateTime.fromISO(dItem.date).toFormat(DATE_FORMAT) === dateItem),
        },
      ]
    })

    return [
      ...fields,
      {
        label: '',
        key: 'actions',
        thStyle: { width: '40px' },
        tdClass: ['action']
      },
      {
        label: '',
        key: 'select',
        thStyle: { width: '40px' },
      },
    ];
  }

  getActionsParams(data): ActionsCellRendererParams<KeywordType> {
    return {
      data: data.item,
      buttons: [
        {
          type: 'delete',
          actionCallback: this.onDeleteAction,
          btnContent: `<i class="fe-trash" />`,
        },
      ]
    };
  }

  onDeleteAction(params: ActionsCellRendererParams<KeywordType>) {
    this.$modal.show('dialog', {
      title: this.$t('common.deleteQuestion'),
      buttons: [
        {
          title: this.$t('common.cancel'),
          handler: () => {
            this.$modal.hide('dialog');
          }
        },
        {
          title: 'OK',
          handler: () => {
            this.productDeleteKeyword({
              id: this.gridFilter.productId!,
              params: params.data.id
            })
              .then(() => {
                this.refreshTable();
                this.$modal.hide('dialog');
                showToast(this.$t('common.successDelete') as any);
              })
          }
        }
      ]
    });
  }

  onAddNew() {
    this.$modal.show(UpdateProductKeywordModal, {
      productId: this.gridFilter.productId,
      updateCallback: () => {
        this.refreshTable();
      }
    }, MODAL_CONFIG);
  }

  onRowSelected(items: KeywordType[]) {
    this.selectedItems = items;
  }

  getDataCount() {
    return this.gridData?.result?.length || 0;
  }

  selectAllRows() {
    this.table?.selectAllRows();
  }

  unselectAllRows() {
    this.table?.clearSelected();
  }

  onClickRow(data) {
    if (data.rowSelected) {
      data.unselectRow();
    } else {
      data.selectRow();
    }
  }

  multipleDelete() {
    const ids = this.selectedItems.map(item => item.id);
    this.$modal.show('dialog', {
      title: this.$t('common.deleteQuestionItems', { count: ids.length }),
      buttons: [
        {
          title: this.$t('common.cancel'),
          handler: () => {
            this.$modal.hide('dialog');
          }
        },
        {
          title: 'OK',
          handler: () => {
            this.productDeleteKeywords({
              id: this.gridFilter.productId!,
              params: ids
            })
              .then(() => {
                this.selectedItems = [];
                this.unselectAllRows();
                this.refreshTable();
                this.$modal.hide('dialog');
                showToast(this.$t('common.successDelete') as any);
              })
          }
        }
      ]
    });
  }

  get dateIntervals() {
    return daysInterval(
      DateTime.fromFormat(this.filter.from, DATE_FORMAT),
      DateTime.fromFormat(this.filter.to, DATE_FORMAT)
    )
  }

  getKWChangedClass(value: number) {
    return {
      'text-success': value > 0,
      'text-danger': value < 0,
      'stats__value': true,
    }
  }

  getNamePreview(value: string) {
    return value?.slice(0, 40);
  }

  onExport() {
    let content: any = [
      {
        label: this.$t('products.name'),
        getValue: (item: KeywordType) => item.productName
      },
      {
        label: this.$t('products.keyword'),
        getValue: (item: KeywordType) => item.keyWord
      },
      {
        label: this.$t('keywordStatisticsReport.isFavourite'),
        getValue: (item: KeywordType) => item.isFavourite ? 'Yes' : 'No'
      },
    ];

    this.dateIntervals.forEach(dateItem => {
      content = [
        ...content,
        {
          label: dateItem,
          getValue: (item: KeywordType) => {
            const data = item?.data.find(dItem => DateTime.fromISO(dItem.date).toFormat(DATE_FORMAT) === dateItem);
            const organic = `${this.$t('keywordStatisticsReport.organic')}: ${data?.organicPosition || 0}`;
            const organicChanges = data?.organicPositionChanges ? `(${data?.organicPositionChanges})` : '';
            const sponsored = `${this.$t('keywordStatisticsReport.sponsored')}: ${data?.sponsoredPosition || 0}`;
            const sponsoredChanges = data?.sponseredPositionChanges ? `(${data?.sponseredPositionChanges})` : '';

            return `${organic}${organicChanges}, ${sponsored}${sponsoredChanges}`;
          },
        },
      ]
    })

    this.xlsExport({
      title:  this.$t('keywordStatisticsReport.keywords') as string,
      action: this.getKeywords,
      content
    })
  }

  onClickFavorite(item: KeywordType) {
    this.productUpdateKeywords({
      id: this.gridFilter.productId!,
      params: [{
        id: item.id,
        isFavourite: !item.isFavourite
      }]
    })
      .then(() => {
        this.refreshTable();
        showToast(this.$t('common.successUpdate') as any);
      })
  }

  formatDate(value) {
    return getShowingDate(value);
  }

  onKeyWordClick(item: KeywordType) {
    this.$modal.show(ShowReportModal, {
      reportComponent: KeywordsChart,
      title: `${this.$t('keywordStatisticsReport.keyword')} - ${item.keyWord}`,
      data: item,
      subTitle: `${this.gridFilter.from} - ${this.gridFilter.to}`,
    }, MODAL_CONFIG);
  }

  openSfrClick(item: KeywordType) {
    const from = DateTime.local().minus({ years: 3 }).toFormat(DATE_FORMAT)
    const to = DateTime.local().toFormat(DATE_FORMAT)

    sfrList({
      from,
      to,
      keywordFilter: item.keyWord,
      take: 1095,
      skip: 0
    })
      .then(response => {
        if (response.result) {
          this.$modal.show(ShowReportModal, {
            reportComponent: SfrChart,
            title: `SFR - ${item.keyWord}`,
            data: response.result,
            subTitle: `${from} - ${to}`,
          }, MODAL_CONFIG);
        }
      })
  }
}
