import { render, staticRenderFns } from "./show-report-modal.vue?vue&type=template&id=37a29d36&scoped=true&"
import script from "./show-report-modal.ts?vue&type=script&lang=ts&"
export * from "./show-report-modal.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a29d36",
  null
  
)

export default component.exports